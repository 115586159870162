import { Component } from "@angular/core";

import { FormGroup } from "@angular/forms";
import { MgFormControlsAccessor, MgControlName } from "./Ujcikk.mg.controls.g";

import { TaskBaseMagicComponent, magicProviders } from "@magic-xpa/angular";

import {
  faBook,
  faUser,
  faWrench,
  faStream,
  faTag,
  faGraduationCap,
  faChartPie,
  faUpload,
  faCaretDown,
  faCaretUp,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "mga-Ujcikk",
  providers: [...magicProviders],
  templateUrl: "../../../templates/_form.html",
  styleUrls: ["./Ujcikk.component.scss"],
})
export class Ujcikk extends TaskBaseMagicComponent {
  mgc = MgControlName;
  mgfc: MgFormControlsAccessor;
  form = this.mgc.Ujcikk;
  form_kiiras = "";
  form_load = false;
  submode = '';//adat kimentéshez

  faBook = faBook;
  faUser = faUser;
  faWrench = faWrench;
  faStream = faStream;
  faTag = faTag;
  faGraduationCap = faGraduationCap;
  faChartPie = faChartPie;
  faUpload = faUpload;

  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;
  faWindowClose = faWindowClose;

  ngAfterViewChecked() {
    if ((typeof (this.mg.getValue(this.mgc.submode)) == 'string') && (this.form_load == false)) {
      //console.log('DIV shown is ' + this.mg.getValue(this.mgc.submode));
      if (this.mg.getValue(this.mgc.submode) == 'KT') {
        this.form_array[0].collapsed = true;//zárt
        this.form_array[3].collapsed = false;//nem zárt
        //this.form_array[3].value = ;//paraméter átadása
        this.submode = 'KT';
      }
      this.form_load = true;
    }
  }

  createFormControlsAccessor(formGroup: FormGroup) {
    this.mgfc = new MgFormControlsAccessor(formGroup, this.magicServices);
  }

  element: HTMLInputElement;
  refresh(id: string, magic): void {
    this.element = document.getElementById(id) as HTMLInputElement;
    this.mg.setValueToControl(magic, this.element.value, true);
    //console.log("NG:"+this.element.value);
    //console.log("MG:"+this.mg.getValue(this.mgc.ISBN));
    //console.log("MG_new:"+this.mg.getValue(magic));
    console.log("futtatas");
  }

  public subjects = this.mgc.subjects;
  public interests = this.mgc.interests;
  public educations = this.mgc.educations;

  public treerefresh(id = ""): void {
    this.element = document.getElementById("subjects") as HTMLInputElement;
    this.mg.setValueToControl(this.mgc.subjects, this.element.value, true);

    this.element = document.getElementById("interests") as HTMLInputElement;
    this.mg.setValueToControl(this.mgc.interests, this.element.value, true);

    this.element = document.getElementById("educations") as HTMLInputElement;
    this.mg.setValueToControl(this.mgc.educations, this.element.value, true);
  }

  public get_mgparameter(id: string): string {
    //return this.mg.getCustomProperty(this.mgc.Ujcikk,'tree');
    return this.mg.getCustomProperty(this.mgc.Ujcikk, id);
  }

  //tree01=this.mg.getCustomProperty('Ujcikk','TREE01');//this.TREE_DATA;//
  public mask_10 = {
    guide: true,
    showMask: true,
    mask: [
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
    ],
  };
  public mask_13 = {
    guide: true,
    showMask: true,
    mask: [
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
    ],
  };
  public mask = this.mask_10;

  public checkValue(event: any) {
    console.log(event);
  }

  public onChecked(obj: any, isChecked: boolean) {
    console.log(obj, isChecked); // {}, true || false
  }

  public contributorsArray: Number[] = [];
  public contributorsType: string[] = [
    "Szerző|A01",
    "Forgatókönyv|A03",
    "Librettó|A04",
    "Tervező, designer|A11",
    "Illusztrátor|A12",
    "Fényképész, fotós|A13",
    "Előszó szerzője|A15",
    "Utószó szerzője|A19",
    "Jegyzetek|A20",
    "Előszó szerzője|A23",
    "Bevezető szerzője|A24",
    "Bevezetés és jegyzetek|A29",
    "Függelék|A33",
    "Rajz|A35",
    "Borítótervező|A36",
    "Térkép|A39",
    "Riporter|A43",
    "Szerkesztő|B01",
    "Lektor|B02",
    "Adaptáció készítője|B05",
    "Fordító|B06",
    "Sorozatszerkesztő|B09",
    "Szerkesztette és fordította|B10",
    "Főszerkesztő|B11",
    "Felelős szerkesztő|B16",
    "Sajtó alá rendezte|B18",
    "Összeállította|C01",
    "Válogatás|C02",
    "Producer|D01",
    "Rendezte|D02",
    "Felolvasó|E07",
    "Közreműködő|Z99",
  ];

  public csakszamok = {
    "0": { pattern: new RegExp("[0-9]") },
    A: { pattern: new RegExp("[0-9a-zA-Z]") },
  };
  ///^(([0-9]-?){9}[0-9xX]|([0-9]-?){13})$/

  public contributorsAdd() {
    this.contributorsArray.push(this.contributorsArray.length + 1);
    //console.log("szerző hozzáadása");
  }
  public contributorsClick() {
    let result: string[] = [];
    let contributorName: string;
    let contributorType: string;

    this.contributorsArray.forEach((element) => {
      contributorName = (<HTMLInputElement>(
        document.getElementById("contributorName" + element)
      )).value;
      contributorType = (<HTMLInputElement>(
        document.getElementById("contributorType" + element)
      )).value;
      result.push(contributorName + contributorType);
    });

    //(<HTMLInputElement>document.getElementById("contributors")).value=result;
    let contributors: HTMLInputElement; // = document.getElementById("contributors");
    //this.contributors=document.getElementById("contributors");
    console.log(result);
  }

  public sectionClick(i: number, label?: string) {
    if ( (this.mg.getValue(this.mgc.puref) != "0") || (this.submode == 'KT'))
    {
      this.form_array[i].collapsed = !this.form_array[i].collapsed;
    }
    this.treerefresh();
    //console.log(this.mg.getValue(this.mgc.puref));
  }

  public theCheckbox = true;

  public form_array = [
    {//s01: alapadatok
      id: "section_01",
      type: "section", //olyan tipus ami több elemett csoportosít
      icon: this.faBook,
      label: "Alapadatok|ISBN, cím, kiadási adatok", //elem kiírás szövege
      perm: "",
      collapsed: false,
      description: "",
      magic: this.mgc.tmp_control,
      visible: "visible",
      value: [
        {
          //column_01_01
          id: "column_01_01",
          type: "column",
          label: "column_01",
          perm: "",
          description: "",
          magic: "",
          icon: "",
          visible: "visible",
          value: [
            {
              id: "section_01_01",
              type: "section", //olyan tipus ami több elemett csoportosít
              label: "Azonosító adatok", //elem kiírás szövege
              perm: "",
              description: "",
              magic: "",
              icon: "",
              visible: "visible",
              value: [
                {
                  id: "section_01_01_05",
                  type: "beszallito_ujcikk",
                  label: "Beszállító",
                  perm: "",
                  description: "",
                  magic_ref: this.mgc.puref,
                  magic_name: this.mgc.puname,
                  magic_btn: this.mgc.pubtn,
                  icon: "",
                  visible: "visible",
                  value: "",
                },
                {
                  id: "section_01_01_01",
                  id_10: "section_01_01_01_a",
                  id_13: "section_01_01_01_b",
                  id_ch: "section_01_01_01_ch",
                  type: "ISBN",
                  label: "ISBN",
                  label_ch: "ISBN13",
                  perm: "",
                  description: "",
                  magic_10: this.mgc.isbn,
                  magic_13: this.mgc.isbn13,
                  magic: this.mgc.isbn,
                  magic_ch_13: this.mgc.isbnis13,
                  icon: "",
                  visible: "visible",
                  value: "",
                },
                {
                  id: "section_01_01_03",
                  type: "vonalkod",
                  label: "Vonalkód",
                  perm: "",
                  description: "",
                  magic: this.mgc.barcode,
                  icon: "",
                  visible: "visible",
                  value: "",
                },
              ],
            },
            {
              id: "section_01_02",
              type: "section", //olyan tipus ami több elemett csoportosít
              label: "Címadatok", //elem kiírás szövege
              perm: "",
              description: "",
              magic: this.mgc.tmp_control,
              icon: "",
              visible: "visible",
              value: [
                {
                  id: "section_01_02_01",
                  type: "text",
                  label: "Cím",
                  perm: "",
                  description: "",
                  magic: this.mgc.title,
                  icon: "",
                  visible: "visible",
                  value: "",
                },
                {
                  id: "section_01_02_02",
                  type: "text",
                  label: "Alcím",
                  perm: "",
                  description: "",
                  magic: this.mgc.subTitle,
                  icon: "",
                  visible: "visible",
                  value: "",
                },
                {
                  id: "section_01_02_03",
                  type: "text",
                  label: "Eredeti cím",
                  perm: "",
                  description: "",
                  magic: this.mgc.originalTitle,
                  icon: "",
                  visible: "visible",
                  value: "",
                },
                {
                  id: "section_01_02_03",
                  type: "text",
                  label: "Publikus cím",
                  perm: "",
                  description: "",
                  magic: this.mgc.publicTitle,
                  icon: "",
                  visible: "",
                  value: "",
                },
                {
                  id: "section_01_02_04",
                  type: "select_vector_multiple",
                  label: "Nyelv",
                  perm: "",
                  description: "",
                  magic: this.mgc.SFlanguages,
                  icon: "",
                  visible: "visible",
                  value: this.mgc.languages,
                },
              ],
            },
          ],
        },
        {
          //column_01_02
          id: "column_01_02",
          type: "column",
          label: "column_02",
          perm: "",
          description: "",
          magic: "",
          icon: "",
          visible: "visible",
          value: [
            {
              id: "section_01_03",
              type: "section",
              label: "Kiadási adatok",
              perm: "",
              description: "",
              magic: this.mgc.tmp_control,
              icon: "",
              visible: "visible",
              value: [
                {
                  id: "section_01_03_01",
                  type: "kiado",
                  label: "Kiadó",
                  perm: "",
                  description: "",
                  magicRef: this.mgc.publisherRef,
                  magicName: this.mgc.publisherName,
                  magicBTN: this.mgc.publisherBTN,
                  icon: "",
                  visible: "visible",
                  value: "",
                },
                {
                  id: "section_01_03_02",
                  type: "text",
                  label: "Megjelenés helye",
                  perm: "",
                  description: "",
                  magic: this.mgc.publishingPlace,
                  icon: "",
                  visible: "visible",
                  value: "",
                },
                {
                  id: "section_01_03_03",
                  type: "text",
                  label: "Megjelenés éve",
                  perm: "",
                  description: "",
                  magic: this.mgc.publishingYear,
                  icon: "",
                  visible: "visible",
                  value: "",
                },
                {
                  id: "section_01_03_04",
                  type: "date",
                  label: "Megjelenés várható időpontja",
                  perm: "",
                  description: "",
                  magic: this.mgc.publishingDate,
                  icon: "",
                  visible: "visible",
                  value: "",
                },
                {
                  id: "section_01_03_04a",
                  type: "date",
                  label: "Első kiadás dátuma",
                  perm: "",
                  description: "",
                  magic: this.mgc.ElsoKiadDat,
                  icon: "",
                  visible: this.mgc.ElsoKiadDat,
                  value: "",
                },
                {
                  id: "section_01_03_04b",
                  type: "date",
                  label: "Forgalomba hozatal dátuma",
                  perm: "",
                  description: "",
                  magic: this.mgc.ForgHozDat,
                  icon: "",
                  visible: this.mgc.ForgHozDat,
                  value: "",
                },
                {
                  id: "section_01_03_05",
                  type: "number",
                  label: "Kiadás sorszáma",
                  perm: "",
                  description: "",
                  magic: this.mgc.editionNumber,
                  icon: "",
                  visible: "visible",
                  value: "",
                },
              ],
            },
            {
              id: "section_01_04",
              id_ch: "section_01_04_ch",
              type: "section_ch",
              label: "Sorozat",
              perm: "",
              description: "",
              magic: this.mgc.tmp_control,
              magic_ch: this.mgc.seriesEnabled,
              icon: "",
              visible: "visible",
              value: [
                {
                  id: "section_01_04_01",
                  type: "series",
                  label: "Sorozat címe",
                  perm: "",
                  description: "",
                  magic: this.mgc.seriesTitle,
                  icon: "",
                  visible: "visible",
                  value: "",
                },
                {
                  id: "section_01_04_04",
                  type: "text",
                  label: "ISSN",
                  perm: "",
                  description: "",
                  magic: this.mgc.issn,
                  icon: "",
                  visible: "visible",
                  value: "",
                },
                {
                  id: "section_01_04_05",
                  type: "number",
                  label: "Kötet száma",
                  perm: "",
                  description: "",
                  magic: this.mgc.volumeNumber,
                  icon: "",
                  visible: "visible",
                  value: "",
                },
              ],
            },
          ],
        },
      ],
    },
    {//s02: szerzők közremüködők
      id: "section_02",
      type: "section",
      icon: this.faUser,
      label: "Szerzők / Közreműködők|Közreműködők, közreműködés típusa",
      perm: "",
      collapsed: true,
      description: "",
      visible: "visible",
      value: [
        {
          id: "section_02_01",
          type: "radio_contributor",
          label: "Szerző választása",
          perm: "",
          description: "",
          magic: this.mgc.Radio_contributor,
          icon: "",
          visible: "visible",
          value: ["Szerzők / Közreműködők", "Nincs szerző"],
        },
        {
          id: "section_02_03",
          type: "contributors",
          label: "Szerző választása",
          perm: "",
          description: "",
          magic: this.mgc.contributors,
          icon: "",
          visible: "visible",
          value: "",
        },
      ],
    },
    {//s03: formátum
      id: "section_03",
      type: "section",
      icon: this.faWrench,
      label: "Formátum|Könyv, hangoskönyv, egyéb",
      perm: "",
      collapsed: true,
      description: "",
      visible: "visible",
      value: [
        {
          id: "section_03_01",
          type: "select2",
          label: "Típus",
          perm: "",
          description: "",
          magic: this.mgc.productType,
          icon: "",
          visible: "visible",
          value: "",
        },
        {
          id: "section_03_02",
          type: "select2",
          label: "Vámtarifaszám", //magicben jelenleg direct lista van, a tényleges tarifaszámkódra mező nincs
          perm: "",
          description: "",
          magic: this.mgc.customsTariffNumber,
          icon: "",
          visible: "visible",
          value: "",
        },
        {
          id: "section_03_99",
          type: "tab",
          label: "Típus",
          perm: "",
          description: "",
          magic: this.mgc.productType,
          icon: "",
          visible: "visible",
          value: [],
        },
      ],
    },
    {//s04: kategória
      id: "section_04",
      type: "section", //olyan tipus ami több elemett csoportosít
      icon: this.faStream,
      label: "Besorolás|Művészetek, jog, orvostudomány, ...", //elem kiírás szövege
      perm: "",
      collapsed: true,
      description: "",
      magic: "",
      visible: "visible",
      value: [
        {
          id: "subjects",
          type: "customtree",
          label: "subjects",
          perm: "",
          description: this.mgc.productType,
          magic: this.mgc.subjects,
          icon: "",
          visible: this.mgc.subjects,
          value: this.submode, //this.get_mgparameter()
        },
        {
          id: "subjectsNB",
          type: "select",
          label: "Besorolás",
          perm: "",
          description: "",
          magic: this.mgc.subjectsNB,
          icon: "",
          visible: this.mgc.subjectsNB,
          value: "", //this.get_mgparameter()
        },
        {
          id: "primarySubject",
          type: "select3",
          label: "Fő besorolás",
          perm: "",
          description: "",
          magic: this.mgc.primarySubject,
          icon: "",
          visible: this.mgc.primarySubject,
          value: "", //this.get_mgparameter()
        },
        {
          id: "oldCategory",
          type: "select2",
          label: "Régi besorolás",
          perm: "",
          description: "",
          magic: this.mgc.oldCategory,
          icon: "",
          visible: this.mgc.oldCategory,
          value: "",
        },
      ],
    },
    {//s05: érdeklődési körök
      id: "section_05",
      type: "section",
      icon: this.faTag,
      label: "Érdeklődés, ünnepek|Életkor, ünnepek, évszakok, csoportok, ...",
      perm: "",
      collapsed: true,
      description: "",
      magic: "",
      visible: "visible",
      value: [
        {
          id: "interests",
          type: "customtree2",
          label: "interests",
          perm: "",
          description: "",
          magic: this.mgc.interests,
          icon: "",
          visible: "visible",
          value: "",
        },
      ],
    },
    {//s06: oktatási minősítők
      id: "section_06",
      type: "section",
      icon: this.faGraduationCap,
      label: "Oktatási minősítők|Felsőoktatás, nyelvtanulás, ...",
      perm: "",
      collapsed: true,
      description: "",
      magic: "",
      visible: "visible",
      value: [
        {
          id: "educations",
          type: "customtree2",
          label: "educations",
          perm: "",
          description: "",
          magic: this.mgc.educations,
          icon: "",
          visible: "visible",
          value: "",
        },
      ],
    },
    {//s07: marketing adatok
      id: "section_07",
      type: "section",
      icon: this.faChartPie,
      label: "Kereskedelmi és marketing adatok|Ár, fülszöveg, stb.",
      perm: "",
      collapsed: true,
      description: "",
      magic: "",
      visible: "visible",
      value: [
        {
          id: "section_07_00",
          type: "select2",
          label: "Engedménykategória",
          perm: "",
          description: "",
          magic: this.mgc.DiscountCategory,
          icon: "",
          visible: "visible",
          value: [],
        },
        {
          id: "section_07_01",
          type: "number",
          label: "Bolti ár(Ft)",
          perm: "",
          description: "",
          magic: this.mgc.price,
          icon: "",
          visible: "visible",
          value: "",
        },
        {
          id: "section_07_02",
          type: "radio_magic",
          label: "Áfa-kulcs",
          perm: "",
          description: "",
          magic: this.mgc.taxRate,
          icon: "",
          visible: "visible",
          value: ["5%", "27%"],
        },
        {
          id: "section_07_04",
          type: "text",
          label: "Egyéb azonosító",
          perm: "",
          description: "",
          magic: this.mgc.miscIdentifier,
          icon: "",
          visible: "visible",
          value: "",
        },
        {
          id: "section_07_05",
          type: "tab2",
          label: "Fülszöveg",
          perm: "",
          description: "",
          magic: this.mgc.blurTabControl,
          icon: "",
          visible: "visible",
          value: [],
        },
        {
          id: "section_07_06",
          type: "text",
          label: "Szlogen",
          perm: "",
          description: "",
          magic: this.mgc.slogan,
          icon: "",
          visible: "visible",
          value: "",
        },
        {
          id: "section_07_07",
          type: "links",
          label: "Linkek",
          perm: "",
          description: "",
          magic: this.mgc.links,
          icon: "",
          visible: "visible",
          value: "",
        },
      ],
    },
    {//s09: Speciális kezelés
      id: "section_09",
      type: "section",
      icon: this.faChartPie,
      label: "Speciális kezelés|...",
      perm: "",
      collapsed: true,
      description: "",
      magic: "",
      visible: "visible",
      value: [
        {
          id: "section_09_01",
          type: "SFSpecial",
          label: "Speciális kezelés",
          perm: "",
          description: "",
          magic: this.mgc.SFSpecial,
          icon: "",
          visible: "visible",
          value: "",
        },
        {
          id: "section_09_02",
          type: "text_read",
          label: "Speciális kezelés",
          perm: "",
          description: "",
          magic: this.mgc.specialtext,
          icon: "",
          visible: "visible",
          value: "",
        }
      ]
    },
    {//s08: fájlok feltöltése
      id: "section_08",
      type: "section",
      icon: this.faUpload,
      label: "Fájlok feltöltése|Borító, mintaoldalak",
      perm: "",
      collapsed: true,
      description: "",
      magic: "",
      visible: "visible",
      value: [
        {
          id: "section_08_00",
          type: "images",
          label: "Képek feltöltése",
          perm: "",
          description: "",
          magic: this.mgc.imagesForm, //this.mgc.coverFileUrl,
          icon: "",
          visible: "visible",
          value: "",
        },
      ],
    },
    {//küldés
      id: "send",
      type: "button_array",//74. sor a templateben
      label: ["Ellenőrzés", "Cikk mentése"],
      perm: "",
      description: "",
      magic: [this.mgc.BTNCHK, this.mgc.BTNSEND], //majd javítandó rendes controlra
      icon: "",
      visible: "visible",
      value: "",
    },
  ];
  get_text(x: string, y: number): string {
    //szövegek szétszedése a | karakter mentén, ezzel a főszöveg és a hozzátartozó leírás külön kezelhető
    return x.split("|")[y];
  }
  getCollapsed(x: string): boolean {
    //összezártság lekérdezése
    return this.form_array[x].collapsed;
  }
}
