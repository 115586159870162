import {
    FormControl,
    FormGroup
} from "@angular/forms";
import {
    MagicServices
} from "@magic-xpa/angular";
export enum MgControlName {
    Form4 = "Form4",
        Table1 = "Table1",
        ColMegnevezes = "ColMegnevezes",
        Megnevezes = "Megnevezes",
        ColSelected = "ColSelected",
        selected = "selected",
        Label10485772 = "Label10485772",
        KODA = "KODA",
        Label10485770 = "Label10485770",
        KODI = "KODI",
        languagesI = "languagesI",
        languagesA = "languagesA",
        languages = "languages",
        b_EgyikSem = "b_EgyikSem"
}
export enum MgCustomProperties {}
export class MgFormControlsAccessor {
    constructor(private fg: FormGroup, private magicServices: MagicServices) {}

    get Megnevezes(): FormControl {
        return this.getTableChildFormControl(MgControlName.Megnevezes);
    }

    get selected(): FormControl {
        return this.getTableChildFormControl(MgControlName.selected);
    }

    get KODA(): FormControl {
        return this.fg.controls[MgControlName.KODA] as FormControl;
    }

    get KODI(): FormControl {
        return this.fg.controls[MgControlName.KODI] as FormControl;
    }

    get languagesI(): FormControl {
        return this.fg.controls[MgControlName.languagesI] as FormControl;
    }

    get languagesA(): FormControl {
        return this.fg.controls[MgControlName.languagesA] as FormControl;
    }

    get languages(): FormControl {
        return this.fg.controls[MgControlName.languages] as FormControl;
    }

    getTableChildFormControl(name: MgControlName): FormControl {
        return this.magicServices.mgAccessorService.getFormGroupByRow(this.magicServices.tableService.getSelectedRow()).controls[name] as FormControl;
    }
}
