import { FormControl, FormGroup } from "@angular/forms";
import { MagicServices } from "@magic-xpa/angular";
export enum MgControlName {
  CikkKereses = "CikkKereses",
  Edit10485761 = "Edit10485761",
  BeszallitoSF = "BeszallitoSF",
  Group10485774 = "Group10485774",
  Label10485775 = "Label10485775",
  aruNevSzures = "aruNevSzures",
  Label10485777 = "Label10485777",
  isbn = "isbn",
  Label10485786 = "Label10485786",
  kiadasEveTol = "kiadasEveTol",
  Label10485784 = "Label10485784",
  kiadasEveIg = "kiadasEveIg",
  Label10485781 = "Label10485781",
  ElsoBeszalltol_0001 = "ElsoBeszalltol_0001",
  Label10485785 = "Label10485785",
  ElsoBeszalltig_0001 = "ElsoBeszalltig_0001",
  Label10485787 = "Label10485787",
  szerzo = "szerzo",
  szerzovg = "szerzovg",
  Label10485789 = "Label10485789",
  kiado = "kiado",
  kiadovg = "kiadovg",
  Label10485791 = "Label10485791",
  sorozat = "sorozat",
  sorozatvg = "sorozatvg",
  Label10485793 = "Label10485793",
  kategoriak = "kategoriak",
  katvg = "katvg",
  MG_B_SEARCH2DEF = "MG_B_SEARCH2DEF",
  frissitvg = "frissitvg",
  hozzaad = "hozzaad",
  SelectedCountTmp = "SelectedCountTmp",
  MG_B_DELETE_TMP = "MG_B_DELETE_TMP",
  valaszttmpvg = "valaszttmpvg",
  SelectedCount = "SelectedCount",
  MG_B_DELETE_RESULT = "MG_B_DELETE_RESULT",
  valasztvg = "valasztvg",
  Label10485766 = "Label10485766",
  tmpidMain = "tmpidMain",
  Label10485768 = "Label10485768",
  tmpidTmp = "tmpidTmp",
  Label10485770 = "Label10485770",
  tmpidCurr = "tmpidCurr",
  allapot = "allapot",
  allapotig = "allapotig",
  BTNSEND = "BTNSEND",
  publisherRef = "publisherRef",
  publisherName = "publisherName",
  PrimarySubj = "PrimarySubj",
  treerefresh = "treerefresh",
  contributorsForm = "contributorsForm",
  linksForm = "linksForm",
  imagesForm = "imagesForm",
  ListaSF = "ListaSF",
  Radio_contributor = "Radio_contributor",
  productType = "productType",
  productTab = "productTab",
  Label84 = "Label84",
  productFormCode = "productFormCode",
  numberOfPages = "numberOfPages",
  Label85 = "Label85",
  Label86 = "Label86",
  productFormDetail = "productFormDetail",
  illustrations = "illustrations",
  Label92 = "Label92",
  numberOfIllustrations = "numberOfIllustrations",
  Label87 = "Label87",
  bookwidth = "bookwidth",
  Label88 = "Label88",
  bookheight = "bookheight",
  Label89 = "Label89",
  bookthickness = "bookthickness",
  Label90 = "Label90",
  bookweight = "bookweight",
  Label93 = "Label93",
  audioBookType = "audioBookType",
  Label94 = "Label94",
  numberOfDiscs = "numberOfDiscs",
  Label95 = "Label95",
  sizeOfAudioBook = "sizeOfAudioBook",
  Label97 = "Label97",
  duration = "duration",
  blurTabControl = "blurTabControl",
  Label104 = "Label104",
  blurb = "blurb",
  btnEditBlurb2 = "btnEditBlurb2",
  blurb2 = "blurb2",
  EgyszeruOsszetett = "EgyszeruOsszetett",
  Label10485813 = "Label10485813",
  BontasTipus = "BontasTipus",
  evhonap = "evhonap",
  bolt = "bolt",
  beszallito = "beszallito",
  cikk = "cikk",
  mennyiseg = "mennyiseg",
  eladasiAr = "eladasiAr",
  listaar = "listaar",
  beszerzesiAr = "beszerzesiAr",
  Keszlet = "Keszlet",
  NullasKeszlet = "NullasKeszlet",
  CBVanKiadv="CBVanKiadv",
  tipus="tipus"
}
export class MgFormControlsAccessor {
  constructor(private fg: FormGroup, private magicServices: MagicServices) {}

  get Edit10485761(): FormControl {
    return this.fg.controls[MgControlName.Edit10485761] as FormControl;
  }

  get aruNevSzures(): FormControl {
    return this.fg.controls[MgControlName.aruNevSzures] as FormControl;
  }

  get isbn(): FormControl {
    return this.fg.controls[MgControlName.isbn] as FormControl;
  }

  get kiadasEveTol(): FormControl {
    return this.fg.controls[MgControlName.kiadasEveTol] as FormControl;
  }

  get kiadasEveIg(): FormControl {
    return this.fg.controls[MgControlName.kiadasEveIg] as FormControl;
  }

  get ElsoBeszalltol_0001(): FormControl {
    return this.fg.controls[MgControlName.ElsoBeszalltol_0001] as FormControl;
  }

  get ElsoBeszalltig_0001(): FormControl {
    return this.fg.controls[MgControlName.ElsoBeszalltig_0001] as FormControl;
  }

  get szerzo(): FormControl {
    return this.fg.controls[MgControlName.szerzo] as FormControl;
  }

  get kiado(): FormControl {
    return this.fg.controls[MgControlName.kiado] as FormControl;
  }

  get sorozat(): FormControl {
    return this.fg.controls[MgControlName.sorozat] as FormControl;
  }

  get kategoriak(): FormControl {
    return this.fg.controls[MgControlName.kategoriak] as FormControl;
  }

  get hozzaad(): FormControl {
    return this.fg.controls[MgControlName.hozzaad] as FormControl;
  }

  get SelectedCountTmp(): FormControl {
    return this.fg.controls[MgControlName.SelectedCountTmp] as FormControl;
  }

  get SelectedCount(): FormControl {
    return this.fg.controls[MgControlName.SelectedCount] as FormControl;
  }

  get tmpidMain(): FormControl {
    return this.fg.controls[MgControlName.tmpidMain] as FormControl;
  }

  get tmpidTmp(): FormControl {
    return this.fg.controls[MgControlName.tmpidTmp] as FormControl;
  }

  get tmpidCurr(): FormControl {
    return this.fg.controls[MgControlName.tmpidCurr] as FormControl;
  }

  getTableChildFormControl(name: MgControlName): FormControl {
    return this.magicServices.mgAccessorService.getFormGroupByRow(
      this.magicServices.tableService.getSelectedRow()
    ).controls[name] as FormControl;
  }
}
